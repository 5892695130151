export function init() {
  topSlider();
}

function topSlider() {
  $('.l-slider-inner').slick({
    dots: false,
    infinite: true,
    autoplaySpeed: 5000,
    speed: 1200,
    slidesToShow: 1,
    autoplay: true,
    pauseOnFocus: false,
    pauseOnHover: false
  });
}