import * as slider from './common/slider.js';
import * as contentView from './common/content-view.js';
import * as smoothScroll from './common/smooth-scroll.js';

window.App = {
  init: function() {
    slider.init(),
    contentView.init(),
    smoothScroll.init()
  }
}

App.init();
