export function init() {
  contentView();
}

function contentView() {
  $('.js-view').on('inview', function(event, isInView, visiblePartX, visiblePartY) {
    if (isInView) {
      $(this).stop(true).addClass('js-view-show');
    }
  });
}
