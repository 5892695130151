export function init() {
  smoothScroll();
  backTop();
}

function smoothScroll() {
  $('a[href^="#"]').on('click', function(){
    var speed = 400;
    var href= $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top;

    $("html, body").animate({
      scrollTop:position
    }, speed, "swing");

    return false;
  });
}

function backTop() {
  var $backTopBtn = $('.link-back');
  var speed = 400;
  $backTopBtn.hide();

  $(window).scroll(function () {
    if ($(this).scrollTop() > 100) {
      $backTopBtn.fadeIn(speed);
    } else {
      $backTopBtn.fadeOut(speed);
    }
  });
}